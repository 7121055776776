<template>
  <div class="reg-container">
    <van-nav-bar :title="title" />
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="form.phone"
          label="手機號碼"
          name=""
          required
          placeholder="Ex. 0900000001 不要有空格"
          :rules="[
            { required: true, message: '手機號必填' },
            { pattern: /\d{10}/, message: '手機號格式錯誤' },
          ]"
          v-if="$route.query.type == 'line'"
        />
        <template v-if="!isRegister">
          <div style="margin: 16px">
            <van-button round block type="primary" @click="onCheckPhone">
              檢查手機號碼
            </van-button>
          </div>
        </template>
        <template v-else-if="isRegister">
          <van-field
            v-model="form.real_name"
            label="真實姓名"
            name=""
            required
            :rules="[{ required: true, message: '真實姓名必填' }]"
          />
          <van-field
            v-model="form.email"
            label="Email"
            name=""
            required
            placeholder="Ex. example@gmail.com  "
            :rules="[{ required: true, message: 'Email必填' }]"
          />
          <div style="margin: 16px">
            <van-button round block type="primary" native-type="submit">
              送出註冊
            </van-button>
            <br />
            <van-button round block type="default" @click="leaveReg">
              以後在說
            </van-button>
          </div>
        </template>
      </van-cell-group>
    </van-form>
  </div>
</template>

<script setup>
import Cookies from "js-cookie";

import { ref, onMounted, computed } from "vue";

import { useStore } from "vuex";
import router from "@/router";
import { useRoute } from "vue-router";

import { Toast } from "vant";

import { register, checkLineId, checkPhone } from "@/api";

const URL = window.URL || window.webkitURL;

const route = useRoute();

const store = useStore();

const form = ref({
  phone: "",
  real_name: "",
  email: "",
  // verify: route.query.verify || null,
  // aid: route.query.aid || undefined,
});

const title = ref("註冊成為會員");

const isRegister = ref(true);

if (route.query.verify) {
  title.value = "會員開通";
}

const validatorUrl = (val) => {
  if (val.length > 0) {
    return /(https?:\/\/|line:\/\/|tel:|mailto:)\S+/.test(val);
  } else {
    return true;
  }
};

const leaveReg = () => {
  // window.location.replace("/error.html");
  router.push("/login");
};

const sso_domain = computed(()=>{
  return store.state.sso_domain
});

onMounted(() => {
  isRegister.value = route.query.type == "mobile" ? true : false;
});

const onCheckPhone = async () => {
  if (form.value.phone.length == 10) {
    let { id_token } = JSON.parse(sessionStorage.getItem("reg"));
    let res = await checkPhone({ token: id_token, phone: form.value.phone });
    if (res.code === 200) {
      Toast("您已是會員，已幫您綁定手機號碼");
      Cookies.set("token", res.data.token, {
        expires: 365,
        domain: sso_domain.value,
      });
      Cookies.set("uid", res.data.uid, {
        expires: 365,
        domain: sso_domain.value,
      });
      router.push("/");
    } else if (res.code === 201) {
      Toast("您是我們的新會員，請輸入基本資料");
      isRegister.value = true;
    } else if (res.code === 202) {
      Toast("該手機號已綁定Line帳號,請使用Line登入，或更換手機號碼");
      router.push('/login')
    } else if (res.code === 401) {
      Toast("line 登入已過期");
      return router.push("/login");
    }
  } else {
    Toast("手機號碼格式錯誤");
  }
};

const onSubmit = async () => {
  let token = null;
  if (route.query.type == "line") {
    let { id_token } = JSON.parse(sessionStorage.getItem("reg"));
    token = id_token;
  } else {
    token = sessionStorage.getItem("reg");
    form.value.phone = token;
  }

  Toast.loading({
    duration: 0,
    message: "資料傳送中...",
    forbidClick: true,
  });

  if (Cookies.get("refer_code")) {
    form.value.refer_code = Cookies.get("refer_code");
  }

  let regRes = await register({
    token: token,
    type: route.query.type,
    ...form.value,
  });

  if (regRes.code === 500) {
    Toast("line 登入已過期");
    return router.push("/login");
  } else if (regRes.code === 200) {
    Toast("註冊成功");
    Cookies.set("token", regRes.data.token, {
      expires: 365,
      domain: store.state.sso_domain,
    });
    Cookies.set("uid", regRes.data.uid, {
      expires: 365,
      domain: store.state.sso_domain,
    });
    return router.push("/");
  } else {
    Toast("註冊失敗");
    return router.push("/login");
  }
};
</script>

<style lang="less" scoped>
.container {
  padding-bottom: 30px;
}
.title {
  font-size: 20px;
  text-align: center;
}

.upload-img {
  width: 80px;
}

.cropper {
  height: 300px;
  // width: 300px;
  background: #ddd;
}

.cropper-section {
  margin: 0 auto;
  position: fixed;
  text-align: center;
  top: 50px;
  // left: 0;
  height: 350px;
  width: 100%;
  max-width: 500px;
  background: #ddd;
  z-index: 8888;
  .crop-area {
    margin: 5 auto;
    width: 100%;
    height: 330px;
  }
  .crop-btn {
    background-color: #666;
    text-align: center;
  }
}
</style>
