<script setup>
import Cookies from "js-cookie";

import { ref, watch, onUnmounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex'

import { Toast } from 'vant'

import { login, sendVerify } from "@/api/auth";

const route = useRoute();
const router = useRouter();

const store = useStore();

const loginMethod = ref('line')

const form = ref({
  username: "",
  password: "",
});

const domain = computed(() => {
  return store.state.domain
});

const sso_domain = computed(() => {
  return store.state.sso_domain
});

const getVerifyMsg = ref("獲取驗證碼");
const showGetVerifyBtn = ref(false);
const countdown = ref(0);

const handleGetVerify = async () => {
  if (!showGetVerifyBtn.value) {
    return
  }
  if (!form.value.username) {
    return Toast("請輸入手機號");
  }
  let res = await sendVerify(form.value.username);
  if (res.code === 200) {
    getVerifyMsg.value = "重新發送";
    countdown.value = 60;
    Toast("驗證碼已發送");
  } else {
    countdown.value = res.data.countdown;
    Toast('登入錯誤');
  }
};

watch(
  () => form.value.username,
  (nVal) => {
    //檢查vVal是否為電話號碼
    let reg = /^09[0-9]{8}$/;

    if (reg.test(nVal)) {
      if (countdown.value === 0) {
        return (showGetVerifyBtn.value = true);
      } else {
        return (showGetVerifyBtn.value = false);
      }
    } else {
      return (showGetVerifyBtn.value = false);
    }
  }
);

let timer = null;

watch(
  () => countdown.value,
  (nVal) => {
    if (nVal > 0) {
      showGetVerifyBtn.value = false;
      if (!timer) {
        timer = setInterval(() => {
          if (countdown.value >= 0) {
            countdown.value--;
          }
        }, 1000);
      }
    } else {
      clearInterval(timer);
      timer = null;
      showGetVerifyBtn.value = true;
    }
  }
);

const handleLogin = async () => {
  //檢查帳號密碼是否為空
  if (!form.value.username || !form.value.password) {
    return Toast("請輸入帳號及驗證碼");
  }

  let res = await login(form.value);
  if (res.code === 200) {
    Cookies.set("token", res.data.token, {
      expires: 365,
      domain: sso_domain.value,
    });
    Cookies.set("uid", res.data.uid, {
      expires: 365,
      domain: sso_domain.value,
    });
    router.push("/");
  } else if (res.code === 201) {
    sessionStorage.setItem("reg", form.value.username);
    router.push({ name: "Register", query: { type: "mobile" } });
  } else {
    Toast(res.message);
  }
};

const handleLineLogin = async () => {
  // router.push('/')
  const client_id = process.env.VUE_APP_LINE_CLINE_ID;

  //判斷domain是否包含localhost
  let redirect_uri = '';
  if (domain.value.includes('localhost')) {
    redirect_uri = 'http://' + domain.value + ":8080/home/linelogin";
  } else {
    redirect_uri = 'https://' + domain.value + "/home/linelogin";
  }

  let link = "https://access.line.me/oauth2/v2.1/authorize?";
  link = link + "response_type=code";
  link += "&client_id=" + client_id;
  link += "&redirect_uri=" + redirect_uri;
  link += "&state=login";
  link += "&scope=openid%20profile";
  window.location.href = link;
};

onUnmounted(() => {
  clearInterval(timer);
});
</script>

<template>
  <!-- Main Start -->
  <main class="main-wrap login-page mb-xxl">
    <!-- Login Section Start -->
    <section class="login-section p-0">
      <!-- Login Form Start -->
      <h1 class="font-md title-color fw-600">會員登入</h1>
      <van-tabs v-model:active="loginMethod" color="green">
        <van-tab title="LINE登入" name="line" />
        <van-tab title="手機登入" name="mobile" />
      </van-tabs>
      <br />
      <template v-if="loginMethod == 'mobile'">
        <form class="custom-form">
          <!-- Email Input start -->
          <div class="input-box">
            <input type="tel" placeholder="手機號" required class="form-control" v-model="form.username" />
            <i class="verify" :class="{ disable: !showGetVerifyBtn }" @click="handleGetVerify">{{ getVerifyMsg }}
              <template v-if="countdown > 0">({{ countdown }})</template>
            </i>
          </div>
          <!-- Email Input End -->

          <!-- Password Input start -->
          <div class="input-box">
            <input type="number" placeholder="驗證碼" required class="form-control" v-model="form.password" />
            <i class="iconly-Hide icli showHidePassword"></i>
          </div>
          <!-- Password Input End -->
          <!-- <a href="javascript:void(0)" class="content-color font-sm forgot mb-3">忘記密碼 ?</a> -->
          <br />
          <button type="button" class="btn-solid" @click="handleLogin">
            登入
          </button>
        </form>
        <!-- Login Form End -->
      </template>

      <template v-if="loginMethod == 'line'">
        <!-- Social Section Start -->
        <div class="socila-section">
          <span>
            <span class="font-sm fw-600 font-theme"> 點擊下方使用Line登入 </span>
          </span>
          <div class="social-wrap">
            <a href="javascript:void(0)" class="font-md title-color fw-600" @click.prevent="handleLineLogin">
              <img src="@/assets/icons/png/line.png" alt="line login" /> Line 登入
            </a>
            <!-- <a href="https://accounts.google.com/ServiceLogin" class="font-md title-color fw-600"><img src="@/assets/icons/png/google.png" alt="phone" /> Continue with Google </a> -->
          </div>
        </div>
        <!-- Social Section End -->
      </template>
    </section>
    <!-- Login Section End -->
  </main>
  <!-- Main End -->
</template>

<style lang="less" scoped>
.input-box {
  .verify {
    font-size: calc(14px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
    position: absolute;
    right: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .disable {
    color: #c9ccd0;
    cursor: not-allowed;
  }
}

.van-tabs__line{  
  background: green !important;
}
</style>
